



// import React, { useState } from "react";
// import Modal from "react-modal";
// import axios from "axios";

// Modal.setAppElement('#root'); // Set the root element for accessibility

// const Conditions = ({ conditions, updateConditions }) => {
//   const [newCondition, setNewCondition] = useState("");
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [conditionList, setConditionList] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");

//   // Open the modal and fetch the conditions
//   const openModal = async () => {
//     setIsModalOpen(true);
//     try {
//       const response = await axios.get("http://localhost:3000/user/conditions");
//       setConditionList(response.data.conditions);
//     } catch (error) {
//       console.error("Error fetching conditions:", error);
//     }
//   };

//   // Close the modal
//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSearchTerm(""); // Reset search term
//   };

//   // Filter the condition list based on search term
//   const filteredConditions = conditionList.filter((condition) =>
//     condition.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   // Handle adding condition
//   const handleAddCondition = (condition) => {
//     updateConditions([...conditions, condition]);
//     closeModal();
//   };

//   return (
//     <div>
//       <table style={{ borderCollapse: "collapse", width: "100%" }}>
//         <thead>
//           <tr>
//             <th style={{ border: "1px solid black", padding: "8px", width: "20%" }}>
//               Sl. No.
//             </th>
//             <th style={{ border: "1px solid black", padding: "8px" }}>Condition</th>
//           </tr>
//         </thead>
//         <tbody>
//           {conditions.map((condition, index) => (
//             <tr key={index}>
//               <td style={{ border: "1px solid black", padding: "8px", textAlign: "center" }}>
//                 {index + 1}
//               </td>
//               <td style={{ border: "1px solid black", padding: "8px" }}>{condition}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <button onClick={openModal} style={{ marginTop: "10px", padding: "8px", width:"100%" }}>
//         + Add Condition
//       </button>

//       {/* Condition Modal */}
//       <Modal
//         isOpen={isModalOpen}
//         onRequestClose={closeModal}
//         style={{
//           content: {
//             top: '50%',
//             left: '50%',
//             right: 'auto',
//             bottom: 'auto',
//             marginRight: '-50%',
//             transform: 'translate(-50%, -50%)',
//             width: '500px',
//             padding: '20px',
//             borderRadius: '10px',
//             boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//             backgroundColor: '#f9f9f9',
//           },
//           overlay: {
//             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//           },
//         }}
//       >
//         <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>Select Condition</h2>
//         <input
//           type="text"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           placeholder="Search condition"
//           style={{
//           margin:"10px",
//             marginBottom: "20px",
//             padding: "10px",
//             width: "90%",
//             borderRadius: "5px", // Rounded corners for input
//             border: "1px solid #ccc",
//           }}
//         />
//         <ul style={{ maxHeight: "200px", overflowY: "scroll", padding: "0" }}>
//           {filteredConditions.map((condition, index) => (
//             <li
//               key={index}
//               onClick={() => handleAddCondition(condition)}
//               style={{
//                 padding: "10px",
//                 cursor: "pointer",
//                 listStyle: "none",
//                 borderBottom: "1px solid #ccc",
//                 borderRadius: "5px",
//                 marginBottom: "5px",
//                 backgroundColor: "#fff",
//                 transition: "background-color 0.3s",
//               }}
//               onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
//               onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#fff")}
//             >
//               {condition}
//             </li>
//           ))}
//         </ul>
//         <button onClick={closeModal} style={{ marginTop: "20px", padding: "10px", width: "100%", borderRadius: "5px", backgroundColor: "#007bff", color: "#fff", border: "none", transition:"" }}>
//           Close
//         </button>
//       </Modal>
//     </div>
//   );
// };

// export default Conditions;





import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import './conditionsnew.css'

Modal.setAppElement('#root');

const Conditions = ({ conditions, updateConditions, patientId, updatePatients }) => {
  const [newCondition, setNewCondition] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [conditionList, setConditionList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const openModal = async () => {
    setIsModalOpen(true);
    try {
      const response = await axios.get("https://api.myaidoc.leuwint.tech/user/conditions");
      setConditionList(response.data.conditions);
    } catch (error) {
      console.error("Error fetching conditions:", error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSearchTerm("");
  };

  const filteredConditions = conditionList.filter((condition) =>
    condition.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddCondition = async (condition) => {
    console.log("Adding condition:", condition);
    console.log("User ID:", patientId);

    fetch('https://api.myaidoc.leuwint.tech/calculate-qrisk', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: patientId, condition:condition }), // Send userId and condition as payload
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to calculate QRisk');
        }
        return response.json();
      })
      .then(() => {
        closeModal();
        return fetch('https://api.myaidoc.leuwint.tech/user/getAllUsers');
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        return response.json();
      })
      .then((data) => {
        updatePatients(data); // Update patients in the parent component
      })
      .catch((error) => console.error('Error:', error));
  };

  return (
    <div className="conditions-container">
    <h3>Conditions</h3>
    <table className="conditions-table">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th>Condition</th>
        </tr>
      </thead>
      <tbody>
        {conditions.map((condition, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{condition}</td>
          </tr>
        ))}
      </tbody>
    </table>

      <button onClick={openModal} style={{ marginTop: "10px", padding: "8px", width:"100%" }}>
        + Add Condition
      </button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '500px',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#f9f9f9',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>Select Condition</h2>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search condition"
          style={{
          margin:"10px",
            marginBottom: "20px",
            padding: "10px",
            width: "90%",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
        <ul style={{ maxHeight: "200px", overflowY: "scroll", padding: "0" }}>
          {filteredConditions.map((condition, index) => (
            <li
              key={index}
              onClick={() => handleAddCondition(condition)}
              style={{
                padding: "10px",
                cursor: "pointer",
                listStyle: "none",
                borderBottom: "1px solid #ccc",
                borderRadius: "5px",
                marginBottom: "5px",
                backgroundColor: "#fff",
                transition: "background-color 0.3s",
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#fff")}
            >
              {condition}
            </li>
          ))}
        </ul>
        <button onClick={closeModal} style={{ marginTop: "20px", padding: "10px", width: "100%", borderRadius: "5px", backgroundColor: "#007bff", color: "#fff", border: "none" }}>
          Close
        </button>
      </Modal>
    </div>
  );
};

export default Conditions;
