

import React, { useState } from 'react';

function VitalsInputField({ initialValue, inputType, userId, updatePatients }) {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    console.log('handle change ',e.target.value)
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    // Log the current value to verify it before making the API call
    console.log("Submitted Value:", value);
   
    // Post the input value to the calculate-qrisk API
    fetch('https://api.myaidoc.leuwint.tech/calculate-qrisk', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  }), // Send the value and userId as payload
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to calculate QRisk');
        }
        return response.json();
      })
      .then(() => {
        // After posting, fetch all users to reset the state
        return fetch('https://api.myaidoc.leuwint.tech/user/getAllUsers');
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        return response.json();
      })
      .then((data) => {
        updatePatients(data); // Update patients in the parent component
      })
      .catch((error) => console.error('Error:', error));
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
      <label
        htmlFor="bp-input"
        style={{
          marginRight: '10px',
          fontSize: '16px',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        }}
      >
        {inputType}
      </label>
      <div style={{ position: 'relative', flex: '1', maxWidth: '250px' }}>
        <input
          id="bp-input"
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={`Enter ${inputType.toLowerCase()}`}
          style={{
            width: '150px',
            padding: '10px 60px 10px 10px', // Added padding on the right for the button
            boxSizing: 'border-box',
            border: '1px solid #ccc',
            borderRadius: '4px',
            fontSize: '16px',
            transition: 'border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
            outline: 'none',
          }}
          onFocus={(e) => (e.target.style.borderColor = '#007BFF')}
          onBlur={(e) => (e.target.style.borderColor = '#ccc')}
        />
        <button
          onClick={handleSubmit}
          style={{
            position: 'absolute',
            right: '10px',
            
            top: '40%',
            transform: 'translateY(-50%)',
            padding: '7px 10px',
            backgroundColor: '#007BFF',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            fontSize: '12px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')}
        >
          Change
        </button>
      </div>
    </div>
  );
}

export default VitalsInputField;
