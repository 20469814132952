// import React from "react";
// import { Link } from "react-router-dom";
// import PatientCard from "./PatientCard";

// const Dashboard = ({ patients }) => {
//   return (
//     <div>
//       <nav>
//         <h1>Dashboard</h1>
//       </nav>
//       <div style={{ display: "flex", justifyContent: "space-between" }}>
//         <div style={{ flex: 1, border: "1px solid black", marginRight: "10px" }}>
//           {/* Empty box */}
//         </div>
//         <div style={{ flex: 1, border: "1px solid black" }}>
//             <h2 style={{marginLeft: "10px"}}>Patients list</h2>
//           {patients.map((patient) => (
//             <Link key={patient.id} to={`/patient/${patient.id}`}>
//               <PatientCard patient={patient} />
//             </Link>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;


// import React from "react";
// import { Link } from "react-router-dom";
// import PatientCard from "./PatientCard";
// import "./Dashboard.css"; // Importing a CSS file for styles


// const Dashboard = ({ patients }) => {
//   return (
//     <div className="dashboard-container">
//       <nav className="navbar">
//         <h1 className="navbar-title">Doctor Dashboard</h1>
//         <ul className="navbar-links">
//           <li><Link to="/">Home</Link></li>
//           <li><Link to="/">About</Link></li>
//           <li><Link to="/">Contact</Link></li>
//         </ul>
//       </nav>
//       <div className="dashboard-content">
//         <div className="empty-box">
//           {/* You can add content or leave it empty */}
//         </div>
//         <div className="patient-list">
//           {patients.map((patient) => (
//             <Link key={patient.id} to={`/patient/${patient.id}`} className="patient-link">
//               <PatientCard patient={patient} />
//             </Link>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;


// import React from "react";
// import { Link } from "react-router-dom";
// import PatientCard from "./PatientCard";
// import "./Dashboard.css";

// const Dashboard = ({ patients }) => {
//   return (
//     <div className="dashboard-container">
//       <nav className="navbar">
//         <h1 className="navbar-title">Doctor Dashboard</h1>
//         <ul className="navbar-links">
//           <li><Link to="/">Home</Link></li>
//           <li><Link to="/">About</Link></li>
//           <li><Link to="/">Contact</Link></li>
//         </ul>
//       </nav>
//       <div className="dashboard-content">
//         <div className="empty-box">
//           <h2>Welcome to the Doctor Dashboard</h2>
//           <p>Select a patient from the list to view details.</p>
//         </div>
//         <div className="patient-list">
//           {patients.map((patient) => (
//             <Link key={patient.id} to={`/patient/${patient.id}`} className="patient-link">
//               <PatientCard patient={patient} />
//             </Link>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;






// import React from "react";
// import { Link } from "react-router-dom";
// import PatientCard from "./PatientCard";
// import "./Dashboard.css";

// const Dashboard = ({ patients }) => {
//   return (
//     <div className="dashboard-container">
//       <nav className="navbar">
//         <h1 className="navbar-title">Doctor Dashboard</h1>
//         <ul className="navbar-links">
//           <li><Link to="/">Home</Link></li>
//           <li><Link to="/">About</Link></li>
//           <li><Link to="/">Contact</Link></li>
//         </ul>
//       </nav>
//       <div className="dashboard-content">
//         <div className="empty-box">
//           <h2>Welcome to the Doctor Dashboard</h2>
//           <p>Select a patient from the list to view details.</p>
//         </div>
//         <div className="patient-list">
//           {patients.map((patient) => (
//             <Link key={patient.id} to={`/patient/${patient.id}`} className="patient-link">
//               <PatientCard patient={patient} />
//             </Link>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;





import React from "react";
import { Link } from "react-router-dom";
import PatientCard from "./PatientCard";
import "./Dashboard.css";
import NavBar from "./navbar";

const Dashboard = ({ patients }) => {
  return (
    <div className="dashboard-container">
      {/* <nav className="navbar">
        <h1 className="navbar-title">Doctor Dashboard</h1>
        <ul className="navbar-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/">About</Link></li>
          <li><Link to="/">Contact</Link></li>
        </ul>
      </nav> */}
      <NavBar Name={"Doctor"}/>
      <div className="dashboard-content">
        <div className="empty-box">
          <h2>Welcome</h2>
          <h2>Dr. Manoj</h2>
          {/* <p>Select a patient from the list or use the search bar to find specific records.</p> */}
          {/* <div className="search-bar">
            <input type="text" placeholder="Search patients..." />
            <button>Search</button>
          </div> */}
        </div>
        <div className="patient-list">

          {patients.map((patient) => (
            <Link key={patient.id} to={`/patient/${patient.id}`} className="patient-link">
              <PatientCard patient={patient} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
