// // Vitals.js
// import React, { useState } from "react";

// const Vitals = ({ vitals, updateVitals }) => {
//   const [newVital, setNewVital] = useState("");

//   const handleAddVital = () => {
//     if (newVital.trim() !== "") {
//       updateVitals([...vitals, newVital]);
//       setNewVital("");
//     }
//   };

//   return (
//     <div>
//       <ul>
//         {vitals.map((vital, index) => (
//           <li key={index}>{vital}</li>
//         ))}
//       </ul>
//       <input
//         type="text"
//         value={newVital}
//         onChange={(e) => setNewVital(e.target.value)}
//         placeholder="Add new vital"
//       />
//       <button onClick={handleAddVital}>+ Add Vital</button>
//     </div>
//   );
// };

// export default Vitals;








// // Vitals.js
// import React, { useState, useEffect } from "react";
// // import "./Vitals.css"; // Import a separate CSS file for styling

// const Vitals = ({ vitals, updateVitals }) => {

 
// console.log('check vitals',vitals)



//   const [newVital, setNewVital] = useState("");

//   const handleAddVital = () => {
//     if (newVital.trim() !== "") {
//       updateVitals([...vitals, newVital]);
//       setNewVital("");
//     }
//   };

//   return (
//     <div>
//       {/* <h2 className="vitals-title">Vitals</h2> */}
//       <ul>
//       {/* {vitals.vitals.map((vitals, index) => (
//           <li key={index}>{vitals}</li>
//         ))} */}
//       </ul>
//       <div className="vitals-input-container">
//         <input
//           type="text"
//           value={newVital}
//           onChange={(e) => setNewVital(e.target.value)}
//           placeholder="Add new vital"
//           className="vitals-input"
//         />
//         <button onClick={handleAddVital} className="vitals-button">
//           + Add Vital
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Vitals;





// import React, { useState } from "react";

// const Vitals = ({ vitals, updateVitals }) => {
//   const [newVitalKey, setNewVitalKey] = useState("");
//   const [newVitalValue, setNewVitalValue] = useState("");

//   const handleAddVital = () => {
//     if (newVitalKey.trim() !== "" && newVitalValue.trim() !== "") {
//       updateVitals({ ...vitals, [newVitalKey]: newVitalValue });
//       setNewVitalKey("");
//       setNewVitalValue("");
//     }
//   };

//   return (
//     <div>
//       <h2>Vitals</h2>
//       <ul>
//         {Object.entries(vitals).map(([key, value], index) => (
//           <li key={index}>
//             <strong>{key}:</strong> {value}
//           </li>
//         ))}
//       </ul>
//       <div className="vitals-input-container">
//         {/* <input
//           type="text"
//           value={newVitalKey}
//           onChange={(e) => setNewVitalKey(e.target.value)}
//           placeholder="Vital Key (e.g., heightCm)"
//           className="vitals-input"
//         />
//         <input
//           type="text"
//           value={newVitalValue}
//           onChange={(e) => setNewVitalValue(e.target.value)}
//           placeholder="Vital Value (e.g., 173)"
//           className="vitals-input"
//         /> */}
//         {/* <button onClick={handleAddVital} className="vitals-button">
//           + Add Vital
//         </button> */}
//       </div>
//     </div>
//   );
// };

// export default Vitals;



import React, { useState } from "react";
import VitalsInputField from "./vitalsInput.js";

import { useDispatch } from 'react-redux';
import { setPatients } from './patientsReducer.js';

import "./patients3.css";

const Vitals = ({ vitals, updateVitals ,patient}) => {

  const dispatch = useDispatch();

  const updatePatients = (newPatients) => {
    dispatch(setPatients(newPatients));
  };


  const [editableVitals, setEditableVitals] = useState({
    heightCm: vitals.heightCm || "",
    weightKg: vitals.weightKg || "",
  });

  const handleVitalChange = (e) => {
    const { name, value } = e.target;
    setEditableVitals({
      ...editableVitals,
      [name]: value,
    });
  };

  const handleUpdateVitals = () => {
    console.log('vitals valuesss', vitals["Height in Cm"])
    console.log('patient valuesss', patient)
    // updateVitals({
    //   ...vitals,
    //   heightCm: editableVitals.heightCm,
    //   weightKg: editableVitals.weightKg,
    // });
  };

//   return (
//     <div>
//       <h3>Vitals</h3>
//       <div style={{ display: "flex", justifyContent: "space-between" }}>
//         <div style={{ flexBasis: "45%" }}>
//           <ul>
//             {Object.entries(vitals).map(([key, value], index) => (
//               <li key={index}>
//                 <strong>{key}:</strong> {value}
//               </li>
//             ))}
//           </ul>
//         </div>

//           {/* <div className="right1">
           
//           <VitalsInputField initialValue={patient.vitals["Height in Cm"]} inputType="Height in Cm" userId={patient.id} updatePatients={updatePatients} />

//           <VitalsInputField initialValue={patient.vitals["Weight in Kg"]} inputType="Weight in Kg" userId={patient.id} updatePatients={updatePatients} />
//           </div>
//           <button onClick={handleUpdateVitals}>Update Vitals</button> */}
        
//       </div>
//     </div>
//   );
// };

return (
  <div className="vitals-container">
    <h3>Vitals</h3>
    <table className="vitals-table">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th>Item</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(vitals).map(([key, value], index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{key}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
    {/* Uncomment this section if you need the VitalsInputField component */}
    {/* <div className="right1">
      <VitalsInputField initialValue={patient.vitals["Height in Cm"]} inputType="Height in Cm" userId={patient.id} updatePatients={updatePatients} />
      <VitalsInputField initialValue={patient.vitals["Weight in Kg"]} inputType="Weight in Kg" userId={patient.id} updatePatients={updatePatients} />
    </div>
    <button onClick={handleUpdateVitals}>Update Vitals</button> */}
  </div>
);
};


export default Vitals;
