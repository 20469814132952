
import React from "react";
import { Link } from "react-router-dom";
import './navbar.css'



const NavBar = ({ Name }) => {
  return (
      <nav className="navbar">
        <h1 className="navbar-title">{Name} Dashboard</h1>
        <ul className="navbar-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/">About</Link></li>
          <li><Link to="/">Contact</Link></li>
        </ul>
      </nav>
  );
};

export default NavBar;
