
// import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Dashboard from "./components/Dashboard";
// import PatientDetails from "./components/PatientDetails";
// import Vitals from "./components/Vitals";
// import patientsss from "./patients.json"

// function App() {
//   const [patient, setPatients] = useState([]);
//   useEffect(() => {
//     fetch("/patients.json")  // Ensure the path is correct
//       .then((response) => response.json())
//       .then((data) => setPatients(data))
//       .catch((error) => console.error("Error loading patient data:", error));
//   }, []);


//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Dashboard patients={patient} />} />
//         {/* <Route path="/patient/:id" element={<PatientDetails patients={patients} />} /> */}
//         <Route path="/patient/:id" element={<PatientDetails patients={patient} setPatients={setPatients} />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;


// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from './components/Dashboard';
import PatientDetails from './components/PatientDetails';
import { setPatients } from './components/patientsReducer.js';
// import patientsss from './patients.json';

function App() {
  const dispatch = useDispatch();
  const patients = useSelector((state) => state.patients.patients);

  useEffect(() => {
    // fetch('/patients.json')
    fetch('https://api.myaidoc.leuwint.tech/user/getAllUsers')
      .then((response) => response.json())
      .then((data) => dispatch(setPatients(data)))
      .catch((error) => console.error('Error loading patient data:', error));
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard patients={patients} />} />
        <Route path="/patient/:id" element={<PatientDetails patients={patients} />} />
      </Routes>
    </Router>
  );
}




export default App;
