import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Vitals from "./Vitals";
import Medications from "./Medications";
import Conditions from "./Conditions";
import NavBar from "./navbar.js";
import "./patients2.css"; // Import the CSS file for styling
import { useSelector } from "react-redux";
import InputField from "./input.js";

import { useDispatch } from 'react-redux';
import { setPatients } from './patientsReducer.js';

const PatientDetails = () => {

  const dispatch = useDispatch();

  const updatePatients = (newPatients) => {
    dispatch(setPatients(newPatients));
  };

  const { id } = useParams();
  const patient = useSelector((state) =>
    state.patients.patients.find((p) => p.id === parseInt(id))
  );

  const [medications, setMedications] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [vitals, setVitals] = useState([]);

  useEffect(() => {
    if (patient) {
      console.log('setMedications ', medications)
      setMedications(patient.medications || []);
      setConditions(patient.conditions || []);
      console.log('patient details opage vitals ',patient.vitals)
      setVitals(patient.vitals || []);
    }
  }, [patient]);

  if (!patient) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard-container">
      <NavBar Name={patient.name} />
      <div className="main-card">
        <div className="patient-details">
          <img
            src={patient.image}
            alt={patient.name}
            className="patient-image"
          />
          <div className="patient-basic-info">
            <h2>{patient.name}'s Details</h2>
            <div className="container">
              <div className="left">
                <p>Age: {patient.age}</p>
                <div className="risk-container">
                  <p>Risk:</p>
                  <div className="risk-progress-bar">
                    <div
                      className="progress-bar-fill"
                      style={{
                        width: `${patient.risk}%`,
                        backgroundColor:
                          patient.risk < 10
                            ? "green"
                            : patient.risk <= 20
                            ? "orange"
                            : "red",
                      }}
                    >
                      <span className="progress-bar-text">{patient.risk}%</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right">
                
                <InputField initialValue={patient.bp} inputType="Systolic Bp:" userId={patient.id} updatePatients={updatePatients} />
                <InputField
                  initialValue={patient.heartRate}
                  inputType="Heart Rate:" userId={patient.id}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cards-container">
          <div className="card vitals-card">
            {/* <h3>Vitals</h3> */}
            <Vitals vitals={vitals} updateVitals={setVitals} patient={patient} />
          </div>
          <div className="med-conditions-row">
            <div className="card vitals-card2">
              <Medications
                medications={medications}
                updateMedications={setMedications}
                patientId={patient.id}
                updatePatients={updatePatients}
              />
            </div>
            <div className="card vitals-card2">
              <Conditions
                conditions={conditions}
                updateConditions={setConditions}
                patientId={patient.id}
                updatePatients={updatePatients}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
