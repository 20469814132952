
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import './medicationscss.css';

Modal.setAppElement('#root'); // Set the root element for accessibility

const Medications = ({ medications, updateMedications, patientId, updatePatients  }) => {
  const [newMedication, setNewMedication] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [medicationList, setMedicationList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Open the modal and fetch the medications
  const openModal = async () => {
    setIsModalOpen(true);
    try {
      const response = await axios.get("https://api.myaidoc.leuwint.tech/user/medications");
      setMedicationList(response.data.medications);
    } catch (error) {
      console.error("Error fetching medications:", error);
    }
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSearchTerm(""); // Reset search term
  };

  // Filter the medication list based on search term
  const filteredMedications = medicationList.filter((med) =>
    med.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle adding medication
  const handleAddMedication = async (medication) => {
    console.log("Adding medication:", medication);
    console.log("User ID:", patientId);

    // try {

      fetch('https://api.myaidoc.leuwint.tech/calculate-qrisk', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: patientId,
             medication, }), // Send the value and userId as payload
      })  .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to calculate QRisk');
        }
        return response.json();
      }).then(() => {
        closeModal();
        // After posting, fetch all users to reset the state
        return fetch('https://api.myaidoc.leuwint.tech/user/getAllUsers');
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        return response.json();
      }).then((data) => {
        updatePatients(data); // Update patients in the parent component
      })
      .catch((error) => console.error('Error:', error));






      // // POST the selected medication with userId to calculate QRisk
      // const payload = {
      //   userId: patientId,
      //   medication,
      // };
      // await axios.post("http://localhost:3000/calculate-qrisk", payload);

      // // Fetch the updated patient data after posting
      // const response = await axios.get("http://localhost:3000/user/getAllUsers");

      // // Update the medications and close the modal
      // updateMedications([...medications, medication]);
      // closeModal();
      
    // } catch (error) {
    //   console.error("Error adding medication:", error);
    // }




  };

//   return (
//     <div>
//       <table style={{ borderCollapse: "collapse", width: "100%" }}>
//         <thead>
//           <tr>
//             <th style={{ border: "1px solid black", padding: "8px", width: "20%" }}>Sl. No.</th>
//             <th style={{ border: "1px solid black", padding: "8px" }}>Medication</th>
//           </tr>
//         </thead>
//         <tbody>
//           {medications.map((medication, index) => (
//             <tr key={index}>
//               <td style={{ border: "1px solid black", padding: "8px", textAlign: "center" }}>
//                 {index + 1}
//               </td>
//               <td style={{ border: "1px solid black", padding: "8px" }}>{medication}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <button onClick={openModal} style={{ marginTop: "10px", padding: "8px", width:"100%", }}>
//         + Add Medication
//       </button>

//       {/* Medication Modal */}
//       <Modal
//         isOpen={isModalOpen}
//         onRequestClose={closeModal}
//         style={{
//           content: {
//             top: '50%',
//             left: '50%',
//             right: 'auto',
//             bottom: 'auto',
//             marginRight: '-50%',
//             transform: 'translate(-50%, -50%)',
//             width: '500px', // Updated width
//             padding: '20px', // Add padding for spacing
//             borderRadius: '10px', // Add rounded corners
//             boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow for depth
//             backgroundColor: '#f9f9f9', // Light background color
//           },
//           overlay: {
//             backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay for focus
//           }
//         }}
//       >
//         <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>Select Medication</h2>
//         <input
//           type="text"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           placeholder="Search medication"
//           style={{
//             margin:"10px",
//             marginBottom: "20px",
//             padding: "10px",
//             width: "90%",
//             borderRadius: "5px", // Rounded corners for input
//             border: "1px solid #ccc",
//           }}
//         />
//         <ul style={{ maxHeight: "200px", overflowY: "scroll", padding: "0" }}>
//           {filteredMedications.map((med, index) => (
//             <li
//               key={index}
//               onClick={() => handleAddMedication(med)}
//               style={{
//                 padding: "10px",
//                 cursor: "pointer",
//                 listStyle: "none",
//                 borderBottom: "1px solid #ccc",
//                 borderRadius: "5px", // Rounded corners for list items
//                 marginBottom: "5px",
//                 backgroundColor: "#fff", // White background for items
//                 transition: "background-color 0.3s", // Smooth hover transition
//               }}
//               onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")} // Hover effect
//               onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#fff")}
//             >
//               {med}
//             </li>
//           ))}
//         </ul>
//         <button onClick={closeModal} style={{ marginTop: "20px", padding: "10px", width: "100%", borderRadius: "5px", backgroundColor: "#007bff", color: "#fff", border: "none" }}>
//           Close
//         </button>
//       </Modal>
//     </div>
//   );
// };




return (
  <div className="medications-container">
    <h3>Medications</h3>
    <table className="medications-table">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th>Medication</th>
        </tr>
      </thead>
      <tbody>
        {medications.map((medication, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{medication}</td>
          </tr>
        ))}
      </tbody>
    </table>

    <button onClick={openModal} className="add-medication-button">
      + Add Medication
    </button>

    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '500px',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#f9f9f9',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }
      }}
    >
      <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>Select Medication</h2>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search medication"
        className="search-input"
      />
      <ul className="medication-list">
        {filteredMedications.map((med, index) => (
          <li
            key={index}
            onClick={() => handleAddMedication(med)}
            className="medication-item"
          >
            {med}
          </li>
        ))}
      </ul>
      <button onClick={closeModal} className="close-modal-button">
        Close
      </button>
    </Modal>
  </div>
);
};

export default Medications;
