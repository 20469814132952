// import React from "react";

// const PatientCard = ({ patient }) => {
//   return (
//     <div style={{ border: "1px solid black", margin: "20px", padding: "20px" }}>
//       <img src={patient.image} alt={patient.name} style={{ width: "60px" }} />
//       <h3>{patient.name}</h3>
//       <p>Age: {patient.age}</p>
//     </div>
//   );
// };

// export default PatientCard;

import React from "react";
import './PatientCard.css'; // Importing the CSS file for styling

const PatientCard = ({ patient }) => {
  return (
    <div className="patient-card">
      <img src={patient.image} alt={patient.name} className="patient-image" />
      <div className="patient-info">
        <h3 className="patient-name">{patient.name}</h3>
        <p className="patient-age">Age: {patient.age}</p>
      </div>
    </div>
  );
};

export default PatientCard;
